<template>
  <div class="article-toc">
    <template v-for="(item, index) in titles" :key="item.anchor">
      <div
        class="title"
        :class="{
          active: activeTitle === item.anchor,
          'post-active': activeIndex && index < activeIndex,
          root: item.level === 1,
          child: item.level === 2,
        }"
      >
        <div class="progress" />
        <a class="toc-link" :href="`#${item.anchor}`">{{ item.title }}</a>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    titles: {
      type: Array,
      required: true,
    },
    activeTitle: {
      type: String,
      required: true,
    },
  },
  computed: {
    activeIndex() {
      for (let i = 0; i < this.titles.length; i++) {
        if (this.activeTitle === this.titles[i].anchor) {
          return i
        }
      }

      return null
    },
  },
}
</script>

<style lang="less" scoped>
.article-toc {
  padding-top: 8px;

  .toc-link {
    font-weight: var(--ds-weight-semi-bold);
    font-size: 16px;
    color: var(--ds-color-gray-50);
    text-decoration: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .title {
    position: relative;
    margin-bottom: 6px;
    padding-top: 6px;

    .progress {
      position: absolute;
      left: -24px;
      top: 0;

      width: 5px;
      height: calc(~'100% + 12px');
      background-color: var(--ds-color-gray-25);
      overflow: hidden;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &:first-child .progress {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child .progress {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &.active {
      .progress {
        background-color: var(--ds-color-primary-100);
      }
      .toc-link {
        color: var(--ds-color-primary-100);
      }
    }

    &.post-active {
      .progress {
        background-color: var(--ds-color-primary-100);
      }
      .toc-link {
        color: var(--ds-color-primary-100);
      }
    }

    &.child {
      margin-left: 18px;

      .progress {
        left: -42px;
      }
    }
  }
}
</style>
